import React from "react";
import Layout from "../../components/layout";
import MartijnEnMarscha from '../../components/portfolio/martijnEnMarscha.component';


export default function MartijnEnMarschaGalleryPage(props){


  return (
    <Layout>
    <MartijnEnMarscha/>
  </Layout>
  )
}



